
import { Component, Vue, Prop } from 'vue-property-decorator';

import Embeddable from '../Common/Embeddable.vue';

import { ItemCyclerSlide, ItemCyclerImageSlide, ItemCyclerEmbeddableSlide, ItemCyclerReiszableImageSlide } from '../../app_code/ItemCycler';

@Component({
  components: {
    Embeddable,
  },
})
export default class ItemCyclerItem extends Vue {
  @Prop()
  private readonly item!: ItemCyclerSlide;

  @Prop()
  private readonly active!: boolean;

  getEmbeddableSlide(): ItemCyclerEmbeddableSlide {
    return this.item as ItemCyclerEmbeddableSlide;
  }

  getImageSlide(): ItemCyclerImageSlide {
    return this.item as ItemCyclerImageSlide;
  }

  getResizableImageSlide(): ItemCyclerReiszableImageSlide {
    return this.item as ItemCyclerReiszableImageSlide;
  }
}
